<template>
  <v-card>
    <v-card-text>todo</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StatsFolder'
}
</script>
