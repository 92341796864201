<template>
  <div id="stats-folder">
    <Headline />
    <StatsFolder />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import StatsFolder from '../../components/stats/StatsFolder'

export default {
  components: {
    Headline,
    StatsFolder
  }
}
</script>
